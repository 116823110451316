import { Link, Stack, Typography, useTheme } from "@mui/material";

export const HeroSection = () => {
  const theme = useTheme();
  return (
    <Stack
      direction={{ lg: "row" }}
      sx={{
        justifyContent: "space-around",
        padding: "70px 80px",
        background: "rgba(1, 2, 4, 0.5)",
        marginTop: "100px",
        [theme.breakpoints.between("xs", "sm")]: {
          margin: "15px",
          padding: "30px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          margin: "30px 30px",
        },
        [theme.breakpoints.between("md", "xl")]: {
          marginRight: "50px",
          marginLeft: "50px",
        },
      }}
    >
      <Stack>
        <Typography
          variant="h1"
          sx={{
            fontSize: "4em",
            lineHeight: "1.2em",
            [theme.breakpoints.between("xs", "sm")]: {
              fontSize: "2.7em",
            },
            [theme.breakpoints.between("sm", "md")]: {
              fontSize: "3em",
            },
          }}
        >
          Your Ultimate
          <br />
          <span style={{ fontWeight: 500 }}>
            <span style={{ color: "#C9A973" }}>Sleep</span> Companion
          </span>
        </Typography>
        <Typography
          style={{
            fontSize: "18px",
            marginTop: "40px",
            maxWidth: "650px",
            lineHeight: "1.5em",
          }}
        >
          Welcome to SOND, the revolutionary sleep content app designed to
          transform your nights and rejuvenate your mornings. Say goodbye to
          restless tossing and turning, and embrace the soothing power of our
          comprehensive collection of sleep-inducing stories, guided
          meditations, ambient sounds, and more.
        </Typography>
        <Stack
          direction={{ md: "row" }}
          spacing={{ xs: 3, sx: 5 }}
          mt={7}
          sx={{
            marginBottom: 10,
            [theme.breakpoints.down("md")]: {
              marginBottom: 0,
              alignItems: "center",
            },
          }}
        >
          <Link
            // href={process.env.REACT_APP_APP_GOOGLE_STORE_URL}
            title="Download for Android"
            rel="noreferrer"
            target="_blank"
            sx={{
              position: "relative",
              ".caption": {
                display: "flex",
                visibility: "hidden",
                position: "absolute",
                left: "50%",
                top: "46%",
                transform: "translate(-50%, -50%)",
                background: "white",
                height: "70px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--bg-main)",
                fontSize: "1.6em",
                fontWeight: "700",
                transition: "opacity 0.3s, visibility 0.3s",
                opacity: 0,
                borderRadius: "10px",
                cursor: "default",
                userSelect: "none",
              },
              "&:hover": {
                ".caption": {
                  visibility: "visible",
                  opacity: 0.85,
                },
              },
            }}
          >
            <img
              src="./images/store-button-google.svg"
              alt="Google Play (Android)"
            />
            <span className="caption">Coming Soon</span>
          </Link>
          <Link
            // href={process.env.REACT_APP_APP_APPLE_STORE_URL}
            title="Download for Android"
            rel="noreferrer"
            target="_blank"
            sx={{
              position: "relative",
              ".caption": {
                display: "flex",
                visibility: "hidden",
                position: "absolute",
                left: "50%",
                top: "46%",
                transform: "translate(-50%, -50%)",
                background: "white",
                height: "70px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--bg-main)",
                fontSize: "1.6em",
                fontWeight: "700",
                transition: "opacity 0.3s, visibility 0.3s",
                opacity: 0,
                borderRadius: "10px",
                cursor: "default",
                userSelect: "none",
              },
              "&:hover": {
                ".caption": {
                  visibility: "visible",
                  opacity: 0.85,
                },
              },
            }}
          >
            <img src="./images/store-button-ios.svg" alt="App Store (iOS)" />
            <span className="caption">Coming Soon</span>
          </Link>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        sx={{
          marginLeft: "50px",
          height: 0,
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
            marginLeft: "0",
            height: "auto",
            marginTop: "50px",
          },
        }}
      >
        <Stack
          sx={{
            width: "100%",
            maxWidth: "360px",
            position: "relative",
            top: "-200px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
            [theme.breakpoints.down("lg")]: {
              top: "0",
            },
          }}
        >
          <img
            src="./images/iphone-mockup-front.webp"
            alt="Home page screenshot"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
