import { Alert, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

export const NewsletterSection = () => {
  const theme = useTheme();
  const [email, setEmail] = useState<string>('');
  const [subscriptionError, setSubscriptionError] = useState<string | null>(null);
  const [isSubscriptionPending, setIsSubscriptionPending] = useState(false);
  const [isSubscriptionSuccess, setIsSubscriptionSuccess] = useState(false);

  function handleNewsletterSubscription(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // Reset state
    setIsSubscriptionPending(true)
    setSubscriptionError(null)
    setIsSubscriptionSuccess(false)

    // Check if email is valid
    const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmailValid = EMAIL_REGEX.test(email);

    // If no, set error state
    if (!isEmailValid) {
      console.log(`Invalid email: ${event.currentTarget.value}`)
      setSubscriptionError('Please provide a valid email address');
      setIsSubscriptionPending(false)
      return;
    }

    // Send email to backend
    const coreApiUrl = process.env.REACT_APP_CORE_API_URL;
    fetch(`${coreApiUrl}/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error subscribing to the newsletter: ${response}`);
        } else {
          console.log('Subscription successful')
          setIsSubscriptionSuccess(true)
        }
      })
      .catch((error) => {
        console.log('Error subscribing to the newsletter', error)
        setSubscriptionError('An error occurred while subscribing to the newsletter. Please try again later.')
      })
      .finally(() => {
        setIsSubscriptionPending(false)
      });
  }

  return(
      <Stack
        spacing={4}
        sx={{
            margin: '200px auto 150px auto',
            textAlign: 'center',
            maxWidth: '900px',
            padding: '0 50px',
            [theme.breakpoints.down('md')] : {
              margin: '100px auto 100px auto',
              padding: '0 30px',
            },
        }}
      >
        <Typography fontSize={{xs: '3em', sm: '4em'}}>
          The sleep revolution is near. <br />
          <span style={{ fontWeight: 700}}>Don’t miss it!</span>
        </Typography>
        <Typography fontSize='1.125em'>
          Want to take your sleep experience 
          to the next level? Be the first to 
          know when our groundbreaking sleep 
          product hits the market. Sign up 
          now to receive exclusive updates, 
          special offers, and priority access. 
          Revolutionize your sleep routine 
          and unlock a world of possibilities. 
          Don't miss out on this extraordinary 
          opportunity. Sign up today!
        </Typography>
        {isSubscriptionPending && <Alert severity='info'>Subscribing to the news...</Alert>}
        {isSubscriptionSuccess && <Alert severity='success'>We've got your email. We will keep you in the loop!</Alert>}
        {subscriptionError && <Alert severity='error' variant='filled'>{subscriptionError}</Alert>}
        {!isSubscriptionSuccess &&
          <Stack direction={{md: 'row'}} spacing={2}>
            <TextField
                value={email}
                disabled={isSubscriptionPending}
                onChange={(event) => setEmail(event.currentTarget.value)}
                type='email'
                placeholder='Enter your e-mail'
                sx={{
                    background: 'white',
                    borderRadius: '10px',
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    height: '55px',
                    fontSize: '18px',
                }} 
            />
            <Button
                onClick={handleNewsletterSubscription}
                disabled={isSubscriptionPending}
                sx={{
                  padding: '15px 20px',
                  borderRadius: '10px',
                  border: 'none',
                  outline: 'none',
                  width: '260px',
                  marginTop: '30px',
                  fontSize: '18px',
                  background: '#C9A973',
                  color: '#051323',
                  fontWeight: 700,
                  cursor: 'pointer',
                  textTransform: 'none',
                  height: '55px',
                  '&:hover': {
                    background: '#C9A973dd',
                  },
                  [theme.breakpoints.down('md')] : {
                    width: '100%',
                  }
                }}
            >
                Subscribe
            </Button>
          </Stack>
        }
      </Stack>
    )
};