import { Link, Stack, Typography, useTheme } from "@mui/material";

export const CtaSection = () => {
  const theme = useTheme();
  return (
    <Stack
      spacing={4}
      direction={{ lg: "row" }}
      sx={{
        marginTop: "200px",
        alignItems: "center",
        padding: "0 50px",
        [theme.breakpoints.down("lg")]: {
          marginTop: "100px",
          padding: "0 30px",
        },
      }}
    >
      <Stack
        spacing={5}
        sx={{
          background: "rgba(1, 2, 4, 0.5)",
          padding: "60px 55px",
          [theme.breakpoints.down("lg")]: {
            padding: "40px 25px",
          },
        }}
      >
        <Typography fontSize="2.5em" fontWeight="500" lineHeight={1.3}>
          Your good night’s sleep <br />
          is <span style={{ color: "#C9A973" }}>one click</span> away.
        </Typography>
        <Typography fontSize="1.125em">
          Ready to experience the sleep of your dreams? Start your journey to
          blissful nights by downloading SOND today. Join our community of
          well-rested individuals and gain unlimited access to our exclusive
          library of sleep content. Say hello to peaceful nights and goodbye to
          restless sleep. It's time to prioritize your well-being.
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        sx={{
          padding: "35px",
          background: "#051323",
          borderRadius: "35px",
          maxWidth: "300px",
          minWidth: "230px",
          [theme.breakpoints.down("lg")]: {
            marginTop: "150px",
            padding: "35px",
          },
        }}
      >
        <Stack
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "35px",
            border: "5px solid #060C19",
            background: "#051120",
            width: "110px",
            height: "110px",
            transform: "rotate(45deg)",
            position: "relative",
            top: "-100px",
          }}
        >
          <img
            src="./images/app-icon.webp"
            alt="Application logo"
            style={{
              width: "60px",
              height: "60px",
              transform: "rotate(-45deg)",
            }}
          />
        </Stack>
        <Stack
          spacing={6}
          style={{
            position: "relative",
            top: "-25px",
          }}
        >
          <Link
            // href={process.env.REACT_APP_APP_GOOGLE_STORE_URL}
            title="Download for Android"
            rel="noreferrer"
            target="_blank"
            sx={{
              position: "relative",
              ".caption": {
                display: "flex",
                visibility: "hidden",
                position: "absolute",
                left: "50%",
                top: "46%",
                transform: "translate(-50%, -50%)",
                background: "white",
                height: "60px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--bg-main)",
                fontSize: "1.5em",
                fontWeight: "700",
                transition: "opacity 0.3s, visibility 0.3s",
                opacity: 0,
                borderRadius: "10px",
                cursor: "default",
                userSelect: "none",
              },
              "&:hover": {
                ".caption": {
                  visibility: "visible",
                  opacity: 0.85,
                },
              },
            }}
          >
            <img
              style={{ width: "100%" }}
              src="./images/store-button-google-color.svg"
              alt="Download from Google Play"
            />
            <span className="caption">Coming Soon</span>
          </Link>
          <Link
            // href={process.env.REACT_APP_APP_APPLE_STORE_URL}
            title="Download for Android"
            rel="noreferrer"
            target="_blank"
            sx={{
              position: "relative",
              ".caption": {
                display: "flex",
                visibility: "hidden",
                position: "absolute",
                left: "50%",
                top: "46%",
                transform: "translate(-50%, -50%)",
                background: "white",
                height: "60px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--bg-main)",
                fontSize: "1.5em",
                fontWeight: "700",
                transition: "opacity 0.3s, visibility 0.3s",
                opacity: 0,
                borderRadius: "10px",
                cursor: "default",
                userSelect: "none",
              },
              "&:hover": {
                ".caption": {
                  visibility: "visible",
                  opacity: 0.85,
                },
              },
            }}
          >
            <img
              style={{ width: "100%" }}
              src="./images/store-button-ios-color.svg"
              alt="Download from Apple Store"
            />
            <span className="caption">Coming Soon</span>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
