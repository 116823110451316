import { Link, Stack, Typography, useTheme } from "@mui/material";

export const Footer = () => {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 2, md: 1 }}
      alignItems="center"
      justifyContent="center"
      p={3}
      sx={{
        background: "linear-gradient(180deg, #010812, rgba(1, 2, 4, 0.5))",
      }}
    >
      <Typography fontSize={{ xs: "0.8em", sm: "1em" }}>
        © Stealth Labs Inc. 2023 All rights reserved
      </Typography>
      <Typography
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        {" "}
        |{" "}
      </Typography>
      <Typography fontSize={{ xs: "0.8em", sm: "1em" }}>
        info@sond.com
      </Typography>
      <Typography
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        {" "}
        |{" "}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          [theme.breakpoints.down("sm")]: {
            fontSize: "0.8em",
          },
        }}
      >
        <Link
          href={process.env.REACT_APP_PRIVACY_POLICY_URL}
          title="Privacy Policy"
          rel="noreferrer"
          target="_blank"
          sx={{
            textDecoration: "none",
            color: "#c9a973",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          Privacy Policy
        </Link>
        <span> | </span>
        <Link
          href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}
          title="Terms of Service"
          rel="noreferrer"
          target="_blank"
          sx={{
            textDecoration: "none",
            color: "#c9a973",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          Terms of Service
        </Link>
      </Stack>
    </Stack>
  );
};
