import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FeatureCard } from '../components/FeatureCard';

const features = [
    {
        iconUrl: './images/icons/solar_music-library-2-broken.svg',
        title: 'Content that fits all ears',
        description: 
          'From relaxing music to guided meditations. '+
          'We have the content to fit all your sleep needs. '+
          'Choose from hundreds of tracks spanned across '+
          'categories such as: asmr, sondscapes, '+
          'sleep stories, masking tracks and more.'
    },
    {
        iconUrl: './images/icons/solar_sleeping-circle-broken.svg',
        title: 'Plan your good night\'s sleep',
        description: 
          'Leave the old playlists behind and organize '+
          'your night with personalized sleep plans. '+
          'Add tracks to help you fall asleep, '+
          'set a masking track to block any disturbances '+
          'during sleep and choose your gentle '+
          'wake-up tracks for a comprehensive '+
          'sleep experience.'
    },
    {
        iconUrl: './images/icons/solar_headphones-round-sound-broken.svg',
        title: 'Filter out the disturbances',
        description: 
        'Don\'t let the noise wake you up. '+
        'Set a timer and a masking track '+
        'will keep playing as you sleep '+
        'protecting you from any external '+
        'sounds that could disturb you.'
    },
    {
      iconUrl: './images/icons/solar_sunrise-broken.svg',
      title: 'The gentled wake-up',
      description: 
      'Alarms don\'t have to be alarming! '+
      'Choose from multiple wake-up tracks '+
      '- from morning meditations, '+
      'positive affirmations, and '+
      'various soundscapes to gently '+
      'bring you into a state of '+
      'wakefulness and help start your day.'
    }, 
    {
      iconUrl: './images/icons/bi_moon-stars.svg',
      title: 'Sleep insights',
      description: 
        'Finally discover what works '+
        'for you - which tracks helped '+
        'you fall sleep, how quickly '+
        'you fell asleep listening. '+
        'Compatible with Apple Watch, '+
        'Fitbit, Garmin and many other '+
        'sleep trackers.'
    },
]

export const FeaturesSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return(
      <Stack alignItems='center' mt={15}>
        <Typography variant='h2' fontSize={40}>
            Features
        </Typography>
        <hr 
          style={{ 
            padding: 0, 
            margin: 0, 
            marginTop: '15px',
            width: '275px',
            height: '1px',
            border: 'none',
            background: 'white'
          }}
        />
        <Typography fontSize={18} maxWidth={1000} textAlign='center' p={4}>
          Imagine effortlessly drifting into 
          a deep slumber as enchanting bedtime 
          stories transport you to distant lands, 
          guided meditations lull you into a state 
          of profound calmness, and gentle ambient 
          sounds envelop you in a cocoon of peace.
        </Typography>
        <Stack
          alignItems='center'
          direction={{xs: 'column-reverse', lg: 'row'}} 
          sx={{
            marginTop: '75px',
          }} 
          spacing={{xs: 0, xl: 10}}
        >
          <Stack sx={{
            [theme.breakpoints.between('xs', 'md')]: {
              padding: '0 30px',
            },
          }}>
          <img 
              src='./images/iphone-mockup-side.webp' 
              alt='Content player screenshot'
              style={{
                width: isMobile ? "100%" : "auto",
                aspectRatio: isMobile ? 1 / 2.2 : "auto",
              }}
            />

          </Stack>
            <Stack spacing={{xs: 0, md: 4}}
              sx={{
                paddingLeft: '35px',
                [theme.breakpoints.between('xs', 'md')]: {
                  padding: '0 30px',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  paddingLeft: '0',
                  padding: '0 60px',
                },
              }}
            >
              {features.map((feature, index) => (
               <FeatureCard
                  key={index}
                  iconUrl={feature.iconUrl}
                  title={feature.title}
                  description={feature.description}
                  variant={index % 2 === 0 ? 'darker' : 'lighter'}
                /> 
              ))}
            </Stack>
        </Stack>
      </Stack>
    )
};
