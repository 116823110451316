import { Stack } from '@mui/material';
import { Header } from './sections/Header';
import { HeroSection } from './sections/HeroSection';
import { FeaturesSection } from './sections/FeaturesSection';
import { CtaSection } from './sections/CtaSection';
import { NewsletterSection } from './sections/NewsletterSection';
import { Footer } from './sections/Footer';

export const HomePage = () => {
  return(
    <Stack style={{
        maxWidth: '1420px',
        margin: '0 auto',
    }}>
      <Header />
      <HeroSection />
      <FeaturesSection />
      <CtaSection />
      <NewsletterSection />
      <Footer />
    </Stack>
  )
};