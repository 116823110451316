import { Stack, Typography, useTheme } from '@mui/material'

interface FeatureCardProps {
    iconUrl: string;
    title: string;
    description: string;
    variant?: 'darker' | 'lighter';
}

export const FeatureCard = ({
    iconUrl,
    title,
    description,
    variant = 'darker',
}: FeatureCardProps) => {
  const theme = useTheme();
    return (
        <Stack direction={{lg: 'row'}} alignItems='center'>
            <Stack 
              style={{
                minWidth: '100px', 
                height: '100px', 
                transform: 'rotate(45deg)',
                borderRadius: '30px',
                background: '#102133',
                zIndex: 2,
              }}
              alignItems='center'
              justifyContent='center'
            >
              <img 
                src={iconUrl}
                alt='Feature icon'
                style={{ 
                    transform: 'rotate(-45deg)',
                  }}
              />
            </Stack>
            <Stack 
              sx={{
                minWidth: '130px', 
                height: '130px', 
                borderRadius: '30px',
                background: variant === 'darker' ? '#051323' : '#0A1A2E',
                transform: 'translateX(-75px) rotate(45deg)',
                [theme.breakpoints.down('lg')] : {
                  left: '0',
                  top: '-125px',
                  transform: 'translateX(0) translateY(-110px) rotate(45deg)',
                }
              }}
              alignItems='center'
              justifyContent='center'
            >
            </Stack>
            <Stack 
              sx={{
                height: '160px', 
                borderRadius: '40px',
                background: variant === 'darker' ? '#051323' : '#0A1A2E',
                transform: 'translateX(-175px)',
                minWidth: 'calc(100% + -110px)',
                [theme.breakpoints.between('xs', 'sm')] : {
                  height: '400px',
                  padding: '30px 0 0 0',
                },
                [theme.breakpoints.between('sm', 'lg')] : {
                  padding: '40px 0',
                  height: '220px',
                },
                [theme.breakpoints.down('lg')] : {
                  transform: 'translateX(0) translateY(-160px)',
                  textAlign: 'center',
                }
              }}
              alignItems='center'
              justifyContent='center'
            >
                <Stack
                  spacing={{xs: 3, sm: 1}}
                  sx={{
                    paddingLeft: '100px',
                    paddingRight: '75px',
                    [theme.breakpoints.between('xs', 'sm')] : {
                      padding: '0 30px',
                    },
                    [theme.breakpoints.between('sm', 'lg')] : {
                      padding: '0 45px',
                    }
                  }}>
                    <Typography fontSize='1.5em' fontWeight='500'>
                      {title}
                    </Typography>
                    <Typography>
                      {description}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}
